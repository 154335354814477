import React from "react";
import { AuthProvider } from "./authContext";
import { ArticlesProvider } from "./articlesContext";
import { MultimediaProvider } from "./multimediaContext";
import { EventsProvider } from "./eventsContext";
import { WineProvider } from "./wineContext";
import { MapAssetsProvider } from "./mapsContext";
import { FiltersProvider } from "./filtersContext";
import { VinousCellarsProvider  } from "./vinousCellarsContext";
import { ToastProvider } from "./toastContext";
import { NextAdapter } from 'next-query-params';
import {QueryParamProvider} from 'use-query-params';

export type VinousProvider = ({ children }: { children: JSX.Element }) => JSX.Element;

const AppProviders: VinousProvider = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <QueryParamProvider adapter={NextAdapter}>
          <WineProvider>
            <ArticlesProvider>
              <FiltersProvider>
                <MultimediaProvider>
                  <EventsProvider>
                    <MapAssetsProvider>
                      <VinousCellarsProvider>
                        { children }
                      </VinousCellarsProvider>
                    </MapAssetsProvider>
                  </EventsProvider>
                </MultimediaProvider>
              </FiltersProvider>
            </ArticlesProvider>
          </WineProvider>
        </QueryParamProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default AppProviders;
