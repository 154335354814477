import axios, { AxiosError } from "axios";

let override: string | null = null;
// Uncomment to make it work locally.
// override = "http://localhost:3000";

// Create Axios instance with base parameters
const instance = axios.create({
  baseURL: override !== null ? override : process.env.REACT_APP_API_URL,
  timeout: 300000,
  withCredentials: true,
});

const baseHeadersForRequest = () => {
  return {
    "Content-Type": "application/json",
    'Accept': '*/*',
  };
};

type Error = {
  status: number;
  err: string;
  code: number;
  stack: any;
};

/**
 * Format error from Axios request for us
 * @param err Axios error object
 */

//Not sure about the right types here, but I had to remove the axios type so its any by default
export const axiosErr = (err): Error | null => {
  if (err.response && err.response.data) {
    // console.log("AxiosErr", err.response);
    return err.response;
  }
  return null;
};

export const get = async (url: string, params?: object | null, cacheHeader?: string, cookie?: any) => {
  const headers = baseHeadersForRequest();
  if (cacheHeader) headers['Cache-Control'] = cacheHeader;
  if (cookie) headers['Cookie'] = cookie;
  return instance({
    url,
    params: params,
    method: "GET",
    headers,
    withCredentials: true,
  }).catch((err) => {
    const error = axiosErr(err);
    throw error ? error : err;
  });;
};

export const post = async (url: string, params: object) => {
  return instance({
    url,
    method: "POST",
    data: params,
    headers: baseHeadersForRequest(),
  }).catch((err) => {
    const error = axiosErr(err);
    throw error ? error : err;
  });
};

export const put = async (url: string, params: object, headers?: object) => {
  return instance({
    url,
    method: "PUT",
    data: params,
    headers: baseHeadersForRequest(),
  }).catch((err) => {
    const error = axiosErr(err);
    throw error ? error : err;
  });
};

export const deleteReq = async (url: string, params: object | null) => {
  return instance({
    url,
    method: "DELETE",
    data: params,
    headers: baseHeadersForRequest(),
  });
};

const client = {
  get,
  post,
  put,
  deleteReq,
};

export default client;
