import { get, post } from "./api"

export const searchResultsPreview = (term: string) => {
  return get("/search/autocomplete_wine_searchable_name", { term })
}

export const getAutoCompleteResults = (term: string) => {
  return get(`/api/v2/search_autocomplete?text=${term}`)
}

export const searchResults = (request: any) => {
  return get("/api/v1/wines", request)
}

export const baseWineSearch = (request: any) => {
  return get("/api/v2/base_wine", request)
}

export const wineVintageSearch = (request: any) => {
  return get("/api/v2/wine_vintages", request)
}

export const producerSearch = (request: any) => {
  return get("/api/v2/producer", request)
}

export const getWines = (request: any) => {
  return get("/api/v2/wines", request)
}

export const getBaseWine = (slug: string, id?: string, wineId?: number) => {
  return get("/api/v2/base_wine/" + slug, { ...(id && { id }), ...(wineId && { wine_id: wineId }) })
}

export const getLivexPriceHistory = (lwin11: string, currency?: string) => {
  return get(`/api/v2/wine/${lwin11}/livex_price_history?currency=${currency}`);
};

export const getWineSearcher = (wine_id: number) => {
  return get("/api/v1/wines/searcher", { wine_id })
}

export const getProducer = (slug: string) => {
  return get("/api/v2/producers/" + slug)
}

export const getVinousFavorites = () => {
  return get("/api/v2/house_favorites/")
}

export const getBasewineFromWineSlug = (slug: string) => {
  return get("/api/v2/basewine_from_wine_slug", { slug })
}

export const getImporter = (slug: string) => {
  return get("/api/v2/importers/" + slug)
}

export const getWinesDetails = (winesIds: number[]) => {
  return post("/api/v2/wine", { ids: winesIds })
}
