import React, { useEffect } from "react";
import { useRouter } from 'next/router';
import { login, logout, loadCurrentUser, saveCurrentPath , updateUserInfo} from "./actions";
import { IAuthContext, authReducerInitialState, authReducer } from "./reducer";
import { VinousProvider } from "..";
import { useToast } from "../toastContext";


const AuthContext = React.createContext<IAuthContext>({
  state: {
    loading: false,
    initialLoad: false,
  },
  dispatch: () => {},
});

const AuthProvider: VinousProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    authReducer,
    authReducerInitialState
  );
  const authValue = {
    state,
    dispatch,
  };
  const router = useRouter();
  const toast = useToast();

  useEffect(() => {
    if (
      router.isReady &&
      !state.user &&
      state.initialLoad &&
      router.pathname !== "/" &&
      router.pathname !== "/users/sign_in" &&
      router.pathname !== "/users/subscription" &&
      router.pathname !== "/users/gift" &&
      router.pathname !== "/users/plan" &&
      router.pathname !== "/users/forgot_password" &&
      router.pathname !== "/statics/[...slug]" &&
      router.pathname !== "/404" &&
      router.pathname !== "/about" &&
      !router.pathname.includes("articles") &&
      !router.pathname.includes("events") &&
      !router.pathname.includes("wines") &&
      !router.pathname.includes("producers") &&
      !router.pathname.includes("search") &&
      !router.pathname.includes("capitalone_enter") &&
      !router.pathname.includes("products")
    ) {
      saveCurrentPath(authValue.dispatch, router.asPath);
      router.push({
        pathname: "/users/sign_in",
        query: { from: router.asPath },
      });
    }
    if (
      state.user &&
      state.initialLoad &&
      (router.pathname === "/users/subscription" ||
      router.pathname === "/users/sign_in" ||
      router.pathname.includes("capitalone_enter"))
    ) {
      toast("error", "You are already logged in.");
      router.push({ pathname: "/" });
    }
  }, [router.pathname, state.initialLoad, router.isReady]);

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth, logout, login, loadCurrentUser, updateUserInfo };
